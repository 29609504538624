import {RouterModule, Routes} from '@angular/router';
import {LayoutHomeComponent} from './layout-home.component';

const APP_ROUTES: Routes = [
  {
    path: '',
    redirectTo: '/module/home',
    pathMatch: 'full'
  },
  {
    path: 'module/home',
    component: LayoutHomeComponent,
    canActivate: ['CoreGuard'],
    data: { needsLogin: true }
  }
];

export const routing = RouterModule.forRoot(APP_ROUTES);
