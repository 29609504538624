export const NAVIGATION = {
  name: 'Benutzerverwaltung',
  icon: 'fa-users',
  path: '/module/ums/',
  children: [
    {
      name: 'Liste',
      path: '/module/ums/user/grid',
      icon: 'fa-table'
    },
    {
      name: 'Neu anlegen',
      path: '/module/ums/user/crud/new',
      icon: 'fa-star'
    },
    {
      name: 'Auth Token',
      path: '/module/ums/auth-token',
      icon: 'fa-key'
    },
  ]
};
