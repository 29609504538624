import {
  Component, ElementRef, Input, OnInit, Renderer2, ViewChild,
  ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, Optional
} from '@angular/core';
import {NgbNav, NgbPopover} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from 'rxjs';

import {MediaCenter} from '@ngmedax/asset';
import {Questionnaire} from '@ngmedax/common-questionnaire-types';
import {ConfigService} from '@ngmedax/config';
import {ValueService} from '@ngmedax/value';
import {Translatable, TranslationEventService, TranslationService} from '@ngmedax/translation';

import {ComponentMapping, QuestionDisplayChangeInterface} from '../../../types';
import {QuestionnaireMediaCenterService} from '../../services/questionnaire-media-center.service';
import {QuestionnaireStateService} from '../../services/questionnaire-state.service';
import {QuestionnaireEditorService} from '../../services/questionnaire-editor.service';
import {preventMultilineText} from '../../froala/prevent-multiline-text';
import {DomHelperService} from '../../services/dom-helper.service';
import {FROALA_LICENSE_KEY} from '../../licences/froala';
import {QuestionnaireVariablesService} from '../../services/questionnaire-variables.service';
import {TRANSLATION_EDITOR_SCOPE} from '../../../constants';
import {KEYS} from '../../../translation-keys';


// hack to inject decorator declarations. must occur before class declaration!
export interface QuestionComponent extends Translatable {}

declare const $: any;
declare const window: any;
const Format = Questionnaire.Container.Format;

/**
 * TODO: we should refactor this component. to much code in one file!
 */
@Component({
  selector: 'app-qa-question',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './question.component.html',
  styleUrls: [
    './question.component.css',
    '../../shared/reusable.css'
  ]
})
@Translatable({scope: TRANSLATION_EDITOR_SCOPE, keys: KEYS})
export class QuestionComponent implements OnInit, OnDestroy {
  @Input() feature = {editor: {patient: {upload: false}}};
  @Input() question: Questionnaire.Container;
  @Input() mapping: ComponentMapping;
  @Input() position: number;
  public conditionType = 'default';
  public disableInlineVarScope: boolean = true;

  @ViewChild('qaQuestion') questionRef: ElementRef;
  @ViewChild('qaQuestionTabs') questionTabs: NgbNav;

  /**
   * Default froala wysiwyg editor options
   * @type {any}
   */
  public descriptionEditorOptions: any = {
    key: FROALA_LICENSE_KEY,
    pluginsEnabled: ['paragraphFormat', 'charCounter', 'codeView', 'wordPaste'],
    toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat', 'html'],
    placeholderText: 'Beschreibung eingeben...',
    quickInsertButtons: [],
    quickInsertTags: [],
    codeMirror: window.CodeMirror,
    codeMirrorOptions: {
      lineNumbers: true,
      mode: {name: 'handlebars', base: 'htmlmixed'}
    },
    charCounterCount: true,
    emoticonsUseImage: false,
    imagePaste: false,
    language: 'de',
    attribution: false
  };

  public titleEditorOptions: any = {
    key: FROALA_LICENSE_KEY,
    events : {'paste.beforeCleanup': preventMultilineText},
    pluginsEnabled: ['wordPaste'],
    toolbarButtons: ['bold', 'italic', 'underline'],
    toolbarVisibleWithoutSelection: true,
    placeholderText: 'Titel eingeben...',
    quickInsertButtons: [],
    wordPasteModal: false,
    pastePlain: true,
    quickInsertTags: [],
    charCounterCount: false,
    toolbarInline: true,
    multiLine: false,
    language: 'de',
  };

  /**
   * Are there any questionnaire variables configured?
   * @type {boolean}
   */
  public hasQuestionnaireVariablesConfig = false;

  /**
   * Can we use the asset module?
   * @type {boolean}
   */
  public hasAssetSupport = false;

  /**
   * Locale for questionnaire. Hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi language support
   * @type {string}
   */
  public locale = 'de_DE';

  /**
   * Subscriptions
   * @type {Subscription[]}
   */
  private subscriptions: Subscription[] = [];

  /**
   * Plugins for extended wysiwyg functionality
   */
  private extendedEditorPlugins = ['paragraphFormat', 'charCounter', 'wordPaste', 'colors', 'align', 'table',
    'lists', 'quote', 'link', 'fontFamily', 'fontSize', 'codeView', 'codeBeautifier', 'specialCharacters', 'emoticons'];

  /**
   * Buttons for extended wysiwyg functionality
   */
  private extendedEditorButtons = {
    moreText: {
      buttons: ['bold', 'italic', 'underline', 'fontFamily', 'fontSize', 'textColor',
        'backgroundColor', 'strikeThrough', 'subscript', 'superscript'],
      align: 'left',
      buttonsVisible: 3
    },

    moreParagraph: {
      buttons: ['paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'quote'],
      align: 'left',
      buttonsVisible: 2
    },

    moreRich: {
      buttons: ['insertLink', 'insertTable', 'emoticons', 'specialCharacters', 'insertHR'],
      align: 'left',
      buttonsVisible: 2
    },

    moreMisc: {
      buttons: ['clearFormatting', 'undo', 'redo', 'html'],
      align: 'right',
      buttonsVisible: 4
    }
  };

  /**
   * Injects dependencies
   */
  public constructor(
    public mediaCenter: QuestionnaireMediaCenterService,
    public domHelper: DomHelperService,
    public value: ValueService,
    private editor: QuestionnaireEditorService,
    private state: QuestionnaireStateService,
    private variables: QuestionnaireVariablesService,
    private config: ConfigService,
    private renderer: Renderer2,
    private ref: ChangeDetectorRef,
    @Optional() protected translationService: TranslationService,
    @Optional() protected translationEventService: TranslationEventService,
  ) {
    this.hasQuestionnaireVariablesConfig
      = !!this.config.get('questionnaire-editor.variable.scopes');

    this.hasAssetSupport = this.mediaCenter.hasAssetSupport();

    const updatePlaceholder = () => {
      this.descriptionEditorOptions.placeholderText = this._(KEYS.EDITOR.ENTER_DESCRIPTION);
      this.titleEditorOptions.placeholderText = this._(KEYS.EDITOR.ENTER_TITLE);
      this.descriptionEditorOptions.language = this.translationService.getLocaleCountryCode(
        this.translationService.getLocale()).toLowerCase();
    };

    updatePlaceholder();
    this.translationEventService && this.translationEventService.onLocaleChanged().subscribe(() => updatePlaceholder());
  }

  /**
   * Runs init code
   */
  public ngOnInit() {
    // enable extended froala editor functions when feature enabled by license
    const isExtendedEditor = !!this.config.get('feature.editor.extendedWysiwyg');

    if (isExtendedEditor) {
      this.descriptionEditorOptions.pluginsEnabled = this.extendedEditorPlugins;
      this.descriptionEditorOptions.toolbarButtons = this.extendedEditorButtons;
    }

    // updates the element in the state service (updates path hashes)
    this.state.updateElement(this.question, this.position);

    // update element on position change
    this.subscriptions.push(this.state.onElementPositionChange.subscribe(() => this.triggerChangeDetection()));

    // get "display change event emitter"
    const questionDisplayChange = this.editor.questionDisplayChange;

    // skips event handling for display change events on "page break" elements
    if (this.question.format !== Questionnaire.Container.Format.PAGE_BREAK) {
      // subscribe to "display change" event
      const subscription = questionDisplayChange.subscribe((displayChange: QuestionDisplayChangeInterface) => {
        for (const cmd of ['expandContents', 'expandDescriptions', 'expandImages', 'expandVideos', 'expandAudio', 'expandConditions']) {

          // expands current tab when true
          if (displayChange[cmd]) {
            displayChange.expandQuestion = true;
            this.questionTabs.select(cmd.replace('expand', '').toLowerCase());
            this.triggerChangeDetection();
          }
        }

        // expands question
        displayChange.expandQuestion && this.domHelper.addClasses(this.questionRef.nativeElement, ['expanded']);

        // folds question
        !displayChange.expandQuestion && this.domHelper.removeClasses(this.questionRef.nativeElement, ['expanded']);
      });

      // add subscription
      this.subscriptions.push(subscription);
    }

    // set title if none found
    !this.question.title && (this.question.title = {[this.locale]: ''});

    // set description if none found
    !this.question.description && (this.question.description = {'type': 'html', 'text': {[this.locale]: ''}});

    // add options if none found
    !this.question.options && (this.question.options = {});

    // set mandatory prop to false if not found
    (typeof this.question.options.mandatory === 'undefined') && (this.question.options.mandatory = false);

    // set active prop to true if not found
    (typeof this.question.options.active === 'undefined') && (this.question.options.active = true);

    // set condition type to "extended" if expression found
    this.question.conditions && this.question.conditions.expression && (this.conditionType = 'extended');

    // question formats where inline variable scope is allowed
    const allowedForInlineVariables = [Format.NUMERIC, Format.DATE, Format.TEXT];

    // allow inline variable scope, if question format is allowed
    allowedForInlineVariables.indexOf(this.question.format) !== -1 && (this.disableInlineVarScope = false);
  }

  /**
   * Unsubscribe from all subscriptions
   */
  public ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  /**
   * Triggers the "element position change" event in order to move the question
   * to the given position
   *
   * @param {number} newPosition
   */
  public onPositionChange(newPosition: number) {
    this.state.changeElementPosition(this.position, newPosition);
  }

  /**
   * Triggers the "questionnaire image position change" event in order to move an image
   * to the given position
   *
   * @param {number} currentPosition
   * @param {number} newPosition
   */
  public onMediaPositionChange(currentPosition: number, newPosition: number, media: string) {
    // get medias
    const medias = this.question.assets[media] || null;

    if (!medias) {
      return;
    }

    // early bailout when changing positions does not make sense
    if (currentPosition >= (medias.length) || newPosition < 0) {
      return;
    }

    // reorder media by current and new position
    medias.splice(newPosition, 0, medias.splice(currentPosition, 1)[0]);
    this.triggerChangeDetection();
  }

  /**
   * Triggers the "element delete" event in order to delete this question
   */
  public onDelete() {
    this.editor.confirmDelete(() => this.state.deleteElement(this.position));
  }

  /**
   * Hides other "add element" popover elements and shows "add element" popover
   * for this question.
   *
   * @param popover
   */
  public onAddElement(popover: NgbPopover) {
    $('ngb-popover-window').hide();

    // wait a moment, so we don't hide the new popover
    setTimeout(() => {
      // hack to always open popover
      popover.close();
      popover.autoClose = false;
      popover.open();

      // scroll to bottom when this question is the last element in the questionnaire
      // this way the popover will be fully visible, as it opens on bottom left position
      if (this.state.isOnLastPosition(this.position)) {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: 'smooth'
        });
      }
    }, 100);
  }

  /**
   * Opens media center modal
   *
   * @param mediaType
   * @param containerAsset
   */
  public onOpenMediaCenterModal(
    mediaType: string,
    containerAsset: Questionnaire.Container.Asset = null
  ) {
    !this.question.assets && (this.question.assets = {});
    !this.question.assets[mediaType] && (this.question.assets[mediaType] = []);

    const locale = this.locale;
    const questionnaireId =  this.state.getQuestionnaire().id;
    const buildAsset = this.mediaCenter.buildContainerAsset;
    const editMode = !!containerAsset;

    const callback = (mediaCenterAsset: MediaCenter.Asset) => {
      (!editMode && mediaCenterAsset) && this.question.assets[mediaType].push(buildAsset({mediaCenterAsset, containerAsset, locale}));
      (editMode && mediaCenterAsset) && buildAsset({mediaCenterAsset, containerAsset, locale});
      this.triggerChangeDetection();
    };

    this.mediaCenter.openMediaCenter({
      questionnaireId, mediaType: <any>mediaType, locale, containerAsset, callback
    });
  }

  /**
   * Deletes media by position and media type
   *
   * @param position
   * @param mediaType
   */
  public onDeleteMedia(position: number, mediaType: string) {
    this.editor.confirmDelete(() => {
      if (!this.question.assets[mediaType]) {
        return;
      }

      this.question.assets[mediaType].splice(position, 1);
      this.triggerChangeDetection();
    });
  }

  /**
   * Opens question tab by given tab id
   *
   * @param el
   * @param cls
   * @param tab
   */
  public openTab(el: HTMLElement, cls: string, tab: string) {
    this.domHelper.addClasses(el, [cls]);
    this.questionTabs.select(tab);
  }

  /**
   * Triggers change detection
   */
  public triggerChangeDetection() {
    this.ref.detectChanges();
    this.ref.markForCheck();
    this.fixVideoPreviews();
  }

  /**
   * Bugfix for google chrome. Forces the browser to reload the video poster images
   *
   * @param files
   */
  public fixVideoPreviews(waitMs = 10) {
    if (!this.question.assets || !this.question.assets.videos) {
      return;
    }
    for (const file of this.question.assets.videos) {
      const preview = file.options && file.options.previewImage && file.options.previewImage[this.locale] ?
        file.options.previewImage[this.locale] : null;

      if (preview) {
        file.options.previewImage[this.locale] = '';

        this.ref.detectChanges();
        this.ref.markForCheck();

        setTimeout(() => {
          file.options.previewImage[this.locale] = preview;

          this.ref.detectChanges();
          this.ref.markForCheck();
        }, waitMs);
      }
    }
  }

  public hasImages(): boolean {
    return !!this.value.get(this.question, ['assets', 'images', 'length']);
  }

  public hasVideos(): boolean {
    return !!this.value.get(this.question, ['assets', 'videos', 'length']);
  }

  public hasAudio(): boolean {
    return !!this.value.get(this.question, ['assets', 'audio', 'length']);
  }

  public hasMedia(): boolean {
    return this.hasImages() || this.hasVideos() || this.hasAudio();
  }

  public hasConditions(): boolean {
    return !!this.value.get(this.question, ['conditions', 'show', 'length'])
      || !!this.value.get(this.question, ['conditions', 'expression', ]);
  }

  public hasVariables(): boolean {
    // @ts-ignore
    return this.variables.getVariableScopeNames().reduce((hasVariables, scope) => hasVariables = (!!this.variables.getMappedVariableName(scope, this.question.pathHash) || hasVariables), false);
  }
}
