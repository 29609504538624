import {versionInfo} from './version/info';

export const config = {
  mobil: {theme: null},
  previewMode: true,
  // fallback for modules using old config key
  assetServer: '{{assets#cdn}}',
  assets: {
    cdn: '{{apis#baseUri}}',
    api: null
  },
  mobile: {
    frontend: {
      uri: '/app'
    }
  },
  apis: {
    login: {
      heartbeat: {
        uri: '{{apis#baseUri}}/api/module/login/heartbeat'
      },
      uri: '{{apis#baseUri}}/api/module/login/auth'
    },

    asset: {
      uri: '{{apis#baseUri}}/api/module/asset',
      cdn: '{{assets#cdn}}/assets/static'
    },

    questionnaire: {
      uri: '{{apis#baseUri}}/api/module/questionnaire',
      group: {
        uri: '{{apis#baseUri}}/api/module/questionnaire/group',
      }
    },
    pdf: {
      form: {
        uri: '{{apis#baseUri}}/api/module/pdf-form'
      }
    },
    filtergroup: {
      uri: '{{apis#baseUri}}/api/module/filtergroup'
    },

    patient: {
      uri: '{{apis#baseUri}}/api/module/patient'
    },
    submission: {
      uri: '{{apis#baseUri}}/api/module/questionnaire/submission',
      assets: {
        cdn: '{{assets#cdn}}/assets/exports'
      }
    },
    mobile : {
      uri : '{{apis#baseUri}}/api/module/mobile'
    },
    device : {
      uri : '{{apis#baseUri}}/api/module/device'
    },
    ums: {
      uri: '{{apis#baseUri}}/api/module/ums',
      resources: {
        user: '/user',
        tenant: '/tenant',
        role: '/role',
        authTokenValidate: '/auth/token/validate'
      },
      filtergroup: {
        uri: '{{apis#baseUri}}/api/module/filtergroup'
      }
    },
    survey: {
      uri: '{{apis#baseUri}}/api/module/survey',
      mail: {
        uri: '{{apis#baseUri}}/api/module/survey/mail'
      }
    },
    settings: {
      uri : '{{apis#baseUri}}/api/module/settings'
    },
    license: {
      manager: {
        uri: '{{apis#baseUri}}/api/module/license'
      },
      info: {
        uri: '{{apis#baseUri}}/api/module/license/info'
      }
    }
  },
  policy: {
    password: {
      regex: ['/.{8,}/', '/[A-Z]{1}/', '/[^A-Za-z0-9]{1}/', '/^[^äöüß]*$/i'],
      hint: 'Das Passwort muss mind. 8 Zeichen lang sein und aus mind. 1 Großbuchstaben '
          + 'und 1 Sonderzeichen bestehen. Folgende Zeichen sind nicht erlaubt: äöüß'
    }
  },
  translation: {
    locales: {
      download: {
        uri: '/assets/locales/%locale%.yaml?ts=%ts%'
      }
    }
  },
  downloadLinks: {
    userGuide: {
      de: null,
      en: null
    },
    allocatorApp: {
      windows: null,
      mac: null,
      linux: null
    }
  },
  'questionnaire-editor': {
    variable: {
      scopes: {
        upload: {
          name: 'Upload',
          tooltip: 'Wird zum Benennen von Assets verwendet, die der Patient während dem Ausfüllen hochläd.',
          allowAccumulation: false,
          allowReturnValue: false
        },
        inline: {
          name: 'Fragebogen',
          tooltip: 'Variablen deren Gültigkeitsbereich sich auf den Fragebogen selbst beschränken.',
          allowAccumulation: false,
          allowReturnValue: false
        },
        fhir: {
          name: 'FHIR Export',
          tooltip: 'Variablen deren Gültigkeitsbereich sich auf den FHIR Export beschränken',
          allowAccumulation: false,
          allowReturnValue: false
        },
        gdt: {
          name: 'GDT Export',
          tooltip: 'Variablen deren Gültigkeitsbereich sich auf den GDT Export beschränken',
          allowAccumulation: true,
          allowReturnValue: true,
          mappings: {
            'Aktuelle Diagnose (6205)': '6205',
            'Befund (6220)': '6220',
            'Fremdbefund (6221)': '6221',
            'Kommentar (6227)': '6227',
            'Dateiinhalt (6304)': '6304',
            'Ergebnistabellentext (6228)': '6228',
            'Test-Ident (8410)': '8410',
            'Testbezeichnung (8411)': '8411',
            'Ergebnis-Text (8480)': '8480'
          }
        },
        client: {
          name: 'Patient',
          tooltip: 'Variablen für die Erfassung von Patientendaten',
          allowAccumulation: false,
          allowReturnValue: false,
          mappings: {
            'Vorname': 'firstName',
            'Nachname': 'lastName',
            'Geburtsdatum': 'birthDate',
            'Ist männlich': 'isMale',
            'Ist weiblich': 'isFemale',
            'Adresse': 'address',
            'Standort': 'location',
            'Patientennummer': 'customerNr',
            'Fallnummer': 'caseNr',
            'Status': 'status'
          }
        }
      }
    }
  },
  tenant: {
    default: {
      hideIfOnlyOne: false
    },
    all: {
      customName: 'Alle'
    }
  },
  feature: {
    patient: {
      app: {
        mail: false,
        link: {
          send: true,
          copy: true,
          open: true
        }
      },
      anonymous: true
    },
    signoSign: true,
    editor: {
      patient: {
        upload: true
      },
      extendedWysiwyg: false
    },
    pdf: {
      forms: false
    }
  },
  constraint: {
    numSurveysPerMonth: 0,
    numQuestionnaires: 0,
    numDevices: 0
  },
  configGenerator: {
    channelUrl: 'https://templates.mymedax.dev/docker-compose'
  },
  versions: {
    base64: versionInfo
  },
  mode: {
    license: {
      manager: false
    }
  }
};


