import {ChangeDetectorRef, Component, OnInit, Optional} from '@angular/core';
import {DATE_FORMAT_YMD, DATE_FORMAT_YMD_HM, Translatable, TranslationService} from '@ngmedax/translation';
import {LayoutService} from '@ngmedax/layout';
import {LicenseManagerService} from '../services/license-manager.service';
import {TRANSLATION_GRID_SCOPE} from '../../../constants';
import {KEYS} from '../../../translation-keys';
import {License} from '../../../types';
import {DateFormatService} from '../../../../translation';
import {LicenseManagerCrudComponent} from './license-manager-crud.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {KeyPairStorageService} from '../services/key-pair-storage.service';


// hack to inject decorator declarations. must occur before class declaration!
export interface LicenseManagerGridComponent extends Translatable {}

@Component({
  selector: 'app-license-manager-grid',
  templateUrl: './license-manager-grid.component.html',
  styleUrls: ['./license-manager-grid.component.css'],
})
@Translatable({scope: TRANSLATION_GRID_SCOPE, keys: KEYS})
export class LicenseManagerGridComponent implements OnInit {
  public isSearchCollapsed = true;
  public isGridCollapsed = false;
  public gridPageNumber = 1;
  public displayPerPage = 25;
  public licenses: License[] = [];
  public filter: {name?: string} = {name: ''};
  public total = 0;

  /**
   * Locale hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi language support
   * @type {string}
   */
  public locale = 'de_DE';

  /**
   * Password given, so we can read and create license keys?
   */
  public isUnlocked = false;

  /**
   * Key password
   */
  public keyPassword = null;

  /**
   * Timeout for filter change event
   */
  private filterChangeTimeout: any = null;

  /**
   * Timeout for show preloader event
   */
  private showPreloaderTimeout: any = null;

  /**
   * date format to use in grid
   * @type {string}
   */
  public get dateFormat() {
    const format = DATE_FORMAT_YMD_HM.replace(/YYYY/, 'YY');
    return this.getDateFormat(format);
  };

  /**
   * date format to use in grid
   * @type {string}
   */
  public get dateFormatYMD() {
    const format = DATE_FORMAT_YMD.replace(/YYYY/, 'YY');
    return this.getDateFormat(format);
  };

  /**
   * Loads licenses
   */
  public ngOnInit() {
    this.licenseManagerService.onLicenseSaved().subscribe(() => this.loadLicenses());
    this.keyPairStorageService.isUnlocked().then(isUnlocked => this.isUnlocked = isUnlocked);
    this.layoutService.showPreloader();
    this.loadLicenses();
  }

  /**
   * Injects dependencies
   */
  public constructor(
    @Optional() private translationService: TranslationService,
    @Optional() private dateFormatService: DateFormatService,
    private keyPairStorageService: KeyPairStorageService,
    private licenseManagerService: LicenseManagerService,
    private layoutService: LayoutService,
    private ref: ChangeDetectorRef,
    private modal: NgbModal
  ) {
  }

  /**
   * Event handler for when paging changes. Triggers change detection and loads licenses
   */
  public onPagingChange() {
    // trigger change detection
    this.ref.detectChanges();
    this.ref.markForCheck();

    this.loadLicenses();
  }

  /**
   * Event handler for when filter changes. Loads licenses
   */
  public onFilterChange() {
    clearTimeout(this.filterChangeTimeout);
    this.filterChangeTimeout = setTimeout(() => this.loadLicenses(), 1000);
  }

  public onUnlock() {
    if (!this.keyPassword) {
      return;
    }

    this.keyPairStorageService.unlock(this.keyPassword).then(isUnlocked => {
      if (isUnlocked) {
        this.isUnlocked = isUnlocked;
        alert('Entsperren erfolgreich.');
        return;
      }

      alert('Fehler: Entsperren nicht möglich. Passwort ist nicht korrekt!')
    });
  }

  public onLock() {
    this.keyPairStorageService.lock();
    this.keyPassword = null;
    this.isUnlocked = false;
    alert('Entsperren aufgehoben.');
  }

  /**
   * Opens license
   */
  public onOpenLicense(license: License) {
    const modalRef = this.modal.open(LicenseManagerCrudComponent, <any>{size: 'fullscreen', backdrop: 'static'});
    license && modalRef.componentInstance.load(license.id);
  }

  /**
   * Creates license
   */
  public onCreateLicense() {
    this.modal.open(LicenseManagerCrudComponent, <any>{size: 'fullscreen', backdrop: 'static'});
  }

  public async onDeleteLicense(license: License) {
    const shouldDelete = await <any>confirm('Sind sie Sicher, dass sie folgende Lizenz löschen möchten: ' + license.name);
    if (!shouldDelete) {
      return;
    }

    await this.licenseManagerService.deleteLicense(license);
    alert('Die Lizenz wurde erfolgreich gelöscht.');
    this.loadLicenses();
  }

  private loadLicenses() {
    this.showPreloader();

    const filter = this.getQueryFilter();
    const opts = this.getQueryOpts();

    this.licenseManagerService.loadLicenses(filter, opts)
      .then((result) => {
        this.licenses = result.rows;
        this.total = result.total;
        this.hidePreloader();
      })
      .catch(error => {
        this.hidePreloader();
        alert(this._(KEYS.LICENSE_MANAGER.GRID.ERROR_LOADING_LICENSES));
        console.log(error);
      });
  }

  /**
   * Returns query filter by filter object
   */
  private getQueryFilter() {
    const filter: any = {};
    Object.keys(this.filter).forEach((key) => filter[key] = { '$regex' : this.filter[key], '$options' : 'i' });
    return filter;
  }

  /**
   * Returns query opts
   */
  private getQueryOpts() {
    return {limit: this.displayPerPage, offset: (this.gridPageNumber - 1) * this.displayPerPage}
  }

  /**
   * Shows preloader after 200ms
   */
  private showPreloader() {
    this.showPreloaderTimeout = setTimeout(() => this.layoutService.showPreloader(), 200);
  }

  /**
   * Hides preloader
   */
  private hidePreloader() {
    clearTimeout(this.showPreloaderTimeout);
    this.layoutService.hidePreloader();
  }
}
