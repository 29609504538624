import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
// import {LayoutService} from '@ngmedax/layout';

import {routing} from './config-generator.routing';
import {ConfigGeneratorCrudComponent} from './components/config-generator-crud.component';
import {ConfigGeneratorService} from './service/config-generator.service';
import {PasswordGeneratorService} from './service/password-generator.service';
import {QueryStringService} from './service/query-string.service';
import {ClipboardService} from './service/clipboard.service';
import {NgxJsonViewerModule} from 'ngx-json-viewer';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxJsonViewerModule,
    NgbModule,
    routing
  ],
  declarations: [
    ConfigGeneratorCrudComponent
  ],
  exports: [],
  providers: [ConfigGeneratorService, PasswordGeneratorService, QueryStringService, ClipboardService]
})
export class ConfigGeneratorModule {
  /*
  constructor(private layoutService: LayoutService) {
    layoutService.addMenuEntry(NAVIGATION);
  }
  */
}
