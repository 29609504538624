import {Questionnaire} from '@ngmedax/common-questionnaire-types';

export const stripTitleHtml = function(container: Questionnaire.Container, locale = 'de_DE') {
  if (!container.elements || !container.elements.length) {
    return;
  }

  container.elements.forEach(element => {
    if (element.title && element.title[locale]) {
      const text = new DOMParser().parseFromString(element.title[locale], 'text/html').body.textContent;
      element.title[locale] = text.replace(/&nbsp;/g, ' ');
    }

    if (element.appendix && element.appendix[locale]) {
      const text = new DOMParser().parseFromString(element.appendix[locale], 'text/html').body.textContent;
      element.appendix[locale] = text.replace(/&nbsp;/g, ' ');
    }

    element.elements && element.elements.length && stripTitleHtml(element, locale);
  })
}
