<div class="qa-config-generator-crud" [ngClass]="{'maximized': displayFullScreen}">
  <h2 class="mb-4">Config Generator</h2>

  <div class="form-group">
    <label for="channel">Repository</label>
    <input class="form-control" type="text" readonly id="channel" value="{{templateUrl}}">
  </div>


  <div class="form-group">
    <label for="selectedVersion">Version</label>
    <select
      id="selectedVersion"
      class="form-control"
      name="selectedVersion"
      [(ngModel)]="selectedVersion"
    >
      <option value="" disabled selected>...</option>
      <option *ngFor="let version of versions" value="{{version}}">{{version}}</option>
    </select>
  </div>

  <div class="form-group">
    <label>Variablen übernehmen</label>
    <br>

    <label class="btn btn-success">
      <i class="fa fa-upload"></i>
      Hochladen
      <input
        type="file"
        (change)="onUpload($event)"
        hidden>
    </label>

    <label class="btn btn-success" *ngIf="hasClipboardApi" (click)="onUpload()">
      <i class="fa fa-clipboard"></i>
      Aus Zwischenablage
    </label>
  </div>

  <hr>

  <div class="group-control">
    <button class="btn btn-primary" (click)="onLoadPack()" [disabled]="!selectedVersion || !versions.length ? true: null">
      <i class="fa fa-download"></i>
      Laden
    </button>
  </div>

  <br/><br/>
  <div *ngIf="form">
    <div class="alert alert-info" role="alert">
      <strong>{{pack.template.description}}</strong>
      | Version: <strong>{{pack.template.version}}</strong>
      | Generiert: <strong>{{pack.template.name}}</strong><br>
    </div>

    <ng-container *ngIf="pack.template.changelog">
      <label>Changelog</label>
      <div class="alert alert-info changelog" role="alert">
        <ul>
          <li *ngFor="let change of pack.template.changelog">{{change}}</li>
        </ul>
      </div>
    </ng-container>

    <ng-container *ngIf="pack.template.versions">
      <label>Versionen</label>
      <div class="alert alert-info version-info" role="alert">
        <ngx-json-viewer [json]="pack.template.versions" [expanded]="false"></ngx-json-viewer>
      </div>
    </ng-container>

    <div class="form-group" *ngIf="pack.template.modes && pack.template.modes.length">
      <label for="mode">Modus</label>
      <select
        id="mode"
        class="form-control"
        name="mode"
        [(ngModel)]="selectedMode"
        (change)="onUpdateMode()"
      >
        <option value="" disabled selected>...</option>
        <option *ngFor="let mode of pack.template.modes" value="{{mode.id}}">{{mode.name}} ({{mode.description}})</option>
      </select>
      <br>
    </div>

    <form [formGroup]="form" class="template-form">
      <ng-container *ngFor="let varName of varNames">
        <div class="form-group" *ngIf="pack.variables[varName].visible">
          <label>
            {{varName}}
            <button
              *ngIf="pack.variables[varName].isLicense"
              [disabled]="form.get(varName).valid ? null : true"
              (click)="onShowLicenseInfo(varName)"
              class="btn btn-primary btn-sm"
            >
              <i class="fa fa-info-circle"></i>
            </button>
          </label>
          <ng-container *ngIf="!pack.variables[varName].options; else select">
            <input
              class="form-control"
              [formControlName]="varName"
              name="{{varName}}"
            >
          </ng-container>
          <ng-template #select>
            <select
              class="form-control"
              [formControlName]="varName"
              name="{{varName}}"
            >
              <option *ngFor="let option of pack.variables[varName].options" value="{{option}}">{{option}}</option>
            </select>
          </ng-template>
          <small id="emailHelp" class="form-text text-muted">{{pack.variables[varName].info || ''}}</small>
          <div class="invalid-feedback" *ngIf="form.get(varName).dirty && form.get(varName).errors && form.get(varName).errors[varName]">
            {{pack.variables[varName].hint || ''}}
          </div>
        </div>
      </ng-container>

      <div class="group-control">
        <button class="btn btn-primary" [disabled]="!form.valid" (click)="onGenerate()">
          <i class="fa fa-cogs"></i>
          Generieren
        </button>
      </div>
    </form>
  </div>
</div>
