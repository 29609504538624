import {Component, Input, Optional} from '@angular/core';
import {Questionnaire} from '@ngmedax/common-questionnaire-types';
import {TRANSLATION_EDITOR_SCOPE} from '../../../../constants';
import {KEYS} from '../../../../translation-keys';
import {Translatable, TranslationService} from '@ngmedax/translation';


// hack to inject decorator declarations. must occur before class declaration!
export interface QuestionTypeTextComponent extends Translatable {}

@Component({
  selector: 'app-qa-question-text',
  template:
      `<textarea class="form-control" disabled placeholder="{{_(KEYS.EDITOR.QUESTION_TYPE_HAS_NO_CONFIGURABLE_CONTENT)}}"></textarea>`
})
@Translatable({scope: TRANSLATION_EDITOR_SCOPE, keys: KEYS})
export class QuestionTypeTextComponent {
  @Input() question: Questionnaire.Container;
  // @Input() mapping: Questionnaire.Container.ComponentMapping;

  /**
   * Injects dependencies
   */
  public constructor(@Optional() private translationService: TranslationService) {
  }
}
