import {ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, Optional} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {DragulaService} from 'ng2-dragula';
import {NgbModal, NgbPopover} from '@ng-bootstrap/ng-bootstrap';

import {MediaCenter} from '@ngmedax/asset';
import {LayoutService} from '@ngmedax/layout';
import {ConfigService} from '@ngmedax/config';
import {Translatable, TranslationEventService, TranslationService} from '@ngmedax/translation';
import {Questionnaire} from '@ngmedax/common-questionnaire-types';

import {dragulaScroll, dragulaScrollStop} from './scripts/dragula-scroll.script';
import {QuestionnaireEditorService} from './services/questionnaire-editor.service';
import {QuestionnaireStateService} from './services/questionnaire-state.service';
import {QuestionnaireTabsService} from './services/questionnaire-tabs.service';
import {QuestionnaireMediaCenterService} from './services/questionnaire-media-center.service';
import {DomHelperService} from './services/dom-helper.service';
import {CONTAINER_FORMAT_TO_COMPONENT_MAPPINGS} from '../mappings';
import {FROALA_LICENSE_KEY} from './licences/froala';
import {Filtergroup} from '../types';
import {TRANSLATION_EDITOR_SCOPE} from '../constants';
import {KEYS} from '../translation-keys';
import {ApiService} from './services/api.service';
import * as sha from 'js-sha256';
import {VariablesModalComponent} from './components/question/variables/modal/variables-modal.component';
import {QuestionnaireVariablesService} from './services/questionnaire-variables.service';
import {ToastService} from '../../../toast';


// hack to inject decorator declarations. must occur before class declaration!
export interface QuestionnaireEditorComponent extends Translatable {}
declare const $: any;

@Component({
  selector: 'app-questionnaire-editor',
  templateUrl: './questionnaire-editor.component.html',
  styleUrls: [
    './questionnaire-editor.component.css',
    './shared/reusable.css'
  ]
})
@Translatable({scope: TRANSLATION_EDITOR_SCOPE, keys: KEYS})
export class QuestionnaireEditorComponent implements OnInit, OnDestroy {

  /**
   * Default froala wysiwyg editor options
   */
  public defaultEditorOptions: any = {
    key: FROALA_LICENSE_KEY,
    placeholderText: 'Beschreibung eingeben...',
    charCounterCount: true,
    quickInsertButtons: [],
    quickInsertTags: [],
    imagePaste: false,
    pluginsEnabled: ['paragraphFormat', 'charCounter'],
    toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat'],
    language: 'de',
    attribution: false
  };

  /**
   * Questionnaire data object
   * @type {Questionnaire}
   */
  public questionnaire: Questionnaire;

  /**
   * JSON snapshot of questionnaire. Used to detect if questionnaire
   * contains unsaved content since last init/load/save action
   */
  public questionnaireSnapshot: string;

  /**
   * Questionnaire path hash map. contains all hashes
   * with pointers to elements in the questionnaire
   */
  public pathHashMap: any = {};

  /**
   * Locale for questionnaire. Hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi language support
   * @type {string}
   */
  public locale = 'de_DE';

  /**
   * Mappings from container format to components
   * @type {any}
   */
  public mappings = CONTAINER_FORMAT_TO_COMPONENT_MAPPINGS;

  /**
   * Filtergroups
   *
   * @type {[{}]}
   */
  public filtergroups: Filtergroup[];

  /**
   * Options for filtergroup select
   */
  public filtergroupSelectOpts = {
    width: '100%',
    placeholder: '...',
    sorter: (data) => {
      return data.sort((a, b) => {
        if (a.id > b.id) {return 1; }
        if (a.id < b.id) {return -1; }
        return 0;
      });
    }
  };

  /**
   * Storage for questionnaire elements (questions and other elements)
   * @type {Array}
   */
  public elements: any[] = [];

  /**
   * Is the input/output overlay visible?
   * @type {boolean}
   */
  public ioOverlayVisible = false;

  /**
   * Are we in save mode?
   * @type {boolean}
   */
  public saveInProgress = false;

  /**
   * Feature object to determine, what feature is active
   * @type {any}
   */
  public feature: any = {
    editor: {
      patient: {
        upload: false
      }
    },
    pdf: {
      forms: false
    }
  };

  /**
   * Emulated container for meta title (used to set gdt variable)
   * @type {any}
   */
  public metaTitleContainer = {pathHash: sha.sha256('meta.title')};

  /**
   * Subscriptions that we must unsubscribe from when component gets destroyed
   */
  private subscriptions: Subscription[] = [];

  /**
   * Are we in edit mode?
   * @type {boolean}
   */
  private editMode = false;

  /**
   * Storage for current drag/drop pos
   */
  private dragPos = {old: 0, new: 0};

  /**
   * Injects dependencies, deletes previous dragula element bags and translates froala placeholder text
   */
  public constructor(
    public mediaCenter: QuestionnaireMediaCenterService,
    public tabs: QuestionnaireTabsService,
    public domHelper: DomHelperService,
    private editor: QuestionnaireEditorService,
    private state: QuestionnaireStateService,
    private configService: ConfigService,
    private layoutService: LayoutService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private dragula: DragulaService,
    private ref: ChangeDetectorRef,
    private api: ApiService,
    private modal: NgbModal,
    private toast: ToastService,
    private variables: QuestionnaireVariablesService,
    @Optional() private translationService: TranslationService,
    @Optional() private translationEvents: TranslationEventService,
  ) {
    /**
     * Bugfix for dragula: Deletes previous elements bag on progressive page reloads
     */
    for (const bag of ['elements-bag', 'images-bag']) {
      this.dragula.find(bag) && this.dragula.destroy(bag);
    }

    const updatePlaceholderText = () => this.defaultEditorOptions.placeholderText = this._(KEYS.EDITOR.ENTER_DESCRIPTION);

    this.translationEvents && this.translationEvents.onLocaleChanged().subscribe(() => {
      updatePlaceholderText();
      this.ref.markForCheck();
      this.ref.detectChanges();

      this.defaultEditorOptions.language = this.translationService.getLocaleCountryCode(
        this.translationService.getLocale()).toLowerCase();

      // hack to force update of question translations
      const questions = this.questionnaire.questions;
      this.questionnaire.questions = [];
      setTimeout(() => this.questionnaire.questions = questions, 100);
    });

    updatePlaceholderText();
  }

  /**
   * Initializes some subscriptions, froala wysiwyg editor and dragula dnd
   */
  public ngOnInit() {
    // shows preloader
    this.layoutService.showPreloader();

    // get editor features
    this.feature.editor = this.configService.get('feature.editor') || this.feature.editor;
    this.feature.pdf.forms = this.configService.get('feature.pdf.forms') || this.feature.pdf.forms;

    // load filter groups and change select2 selection on questionnaire loading
    const filtergroupChange = this.state.onSetQuestionnaire.subscribe(async (questionnaire) => {
      this.filtergroups = await this.editor.loadFiltergroups();
    });

    this.subscriptions.push(filtergroupChange);

    if (document && document.location && document.location.pathname && !document.location.pathname.match(/editor\/.*$/)) {
      this.applyRestrictions();
    }

    // init a new questionnaire and link it to the state service
    this.startSession(this.editor.initQuestionnaire(this.pathHashMap));

    // only allow dragging via elements that contain the class: qa-handle-control
    this.dragula.createGroup('elements-bag', {
      moves: (el, container, handle) => handle.classList.contains('qa-handle-control')
    });

    // auto scroll the editor, when dragging an element
    this.subscriptions.push(this.dragula.drag('elements-bag').subscribe((opts) => {
      this.dragPos.old = [...<any>opts.el.parentNode.children].indexOf(opts.el);
      dragulaScroll();
    }));

    // trigger change detection and stop scrolling when element was dropped
    this.subscriptions.push(this.dragula.drop('elements-bag').subscribe((opts) => {
      this.dragPos.new = [...<any>opts.target.children].indexOf(opts.el);
      this.state.changeElementPosition(this.dragPos.old, this.dragPos.new);
      dragulaScrollStop();
    }));

    // stop scrolling if cancel event was triggered
    this.subscriptions.push(this.dragula.cancel('elements-bag').subscribe(dragulaScrollStop));

    /**
     * Load questionnaire by params.id on routing event
     */
    const routeSubscription = this.activatedRoute.params.subscribe(async (params: {id: string}) => {
      await this.loadQuestionnaireById(params.id);
    });

    this.subscriptions.push(routeSubscription);
    this.askResumeSession();
  }

  /**
   * Unsubscribe from all subscriptions
   */
  public ngOnDestroy() {
    this.memorizeSession();

    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  /**
   * Memorized questionnaire on page leave
   */
  @HostListener('window:beforeunload', ['$event'])
  memorizeQuestionnaireOnPageLeave() {
    this.memorizeSession();
  }

  /**
   * Loads questionnaire by given id
   *
   * @param id
   */
  public async loadQuestionnaireById(id: string) {
    if (!id) {
      // hides preloader
      this.layoutService.hidePreloader();
      return;
    }

    // id given, so we are in edit mode
    this.editMode = true;

    // shows preloader
    this.layoutService.showPreloader();

    try {
      this.startSession(await this.editor.loadQuestionnaire(id, this.pathHashMap));
      this.layoutService.hidePreloader();
    } catch (error) {
      alert(this._(KEYS.EDITOR.ERROR_LOADING_QUESTIONNAIRE));
      console.log(error);
    }
  }

  /**
   * Event handler for when we should save the questionnaire
   */
  public async onSaveQuestionnaire() {
    if (!this.questionnaire || !this.questionnaire.meta || !this.questionnaire.meta.title[this.locale]) {
      alert(this._(KEYS.EDITOR.PLEASE_INSERT_TITLE_TO_SAVE));
      return;
    }

    this.saveInProgress = true;

    try {
      await this.editor.saveQuestionnaire(this.editMode);

      /**
       * NOTE: The components can alter the questionnaire when rendering it. Therefore we have to wait a moment.
       * otherwise the "is dirty" check might not work correctly...
       */
      this.questionnaire = await this.editor.loadQuestionnaire(this.questionnaire.id, this.pathHashMap);
      await new Promise(resolve => setTimeout(resolve, 200));

      this.startSession(this.questionnaire);
      this.editMode = true;
      this.saveInProgress = false;
      alert(this._(KEYS.EDITOR.SUCCESSFULLY_SAVED_QUESTIONNAIRE));
    } catch (error) {
      this.saveInProgress = false;
      alert(this._(KEYS.EDITOR.ERROR_SAVING_QUESTIONNAIRE));
      console.error(error);
    }
  }

  /**
   * Opens media center modal
   *
   * @param mediaType
   * @param containerAsset
   */
  public onOpenMediaCenterModal(containerAsset: Questionnaire.Container.Asset = null) {
    const locale = this.locale;
    const questionnaireId =  this.state.getQuestionnaire().id;
    const buildAsset = this.mediaCenter.buildContainerAsset;
    const maintainAspectRatio = true;
    const aspectRatio = 16 / 3.8;

    const callback = (mediaCenterAsset: MediaCenter.Asset) => {
      containerAsset = buildAsset({mediaCenterAsset, containerAsset, locale});
      this.questionnaire.meta.asset.image = containerAsset;
    };

    this.mediaCenter.openMediaCenter({
      questionnaireId, mediaType: 'images', locale, containerAsset, callback, maintainAspectRatio, aspectRatio
    });
  }

  /**
   * Deleted meta asset image object of this questionnaire
   */
  public onDeleteImage() {
    if (!this.questionnaire.meta.asset.image) {
      return;
    }

    this.editor.confirmDelete(() => delete(this.questionnaire.meta.asset.image));
  }

  /**
   * Jumps to question
   *
   * @param popover
   */
  public onJumpToQuestion(popover: NgbPopover) {
    $('ngb-popover-window').hide();

    // wait a moment, so we don't hide the new popover
    setTimeout(() => {popover.close(); popover.open(); }, 100);
  }

  /**
   * Opens modal for gdt meta title variables
   */
  public onGdtMetaTitleVariablesModal() {
    const modalRef = this.modal.open(VariablesModalComponent, {size: 'lg'});
    modalRef.componentInstance.container = this.metaTitleContainer;
    modalRef.componentInstance.disabledScopes = {upload: true, inline: true, pdf: true, client: true, fhir: true, 'pdf-form': true};
  }

  /**
   * Opens modal for gdt meta scoring variables
   *
   * @param {string} scoringVarName
   */
  public onGdtMetaScoringVariablesModal(scoringVarName: string) {
    const virtualContainer = {pathHash: sha.sha256(scoringVarName)};
    const modalRef = this.modal.open(VariablesModalComponent, {size: 'lg'});
    modalRef.componentInstance.container = virtualContainer;
    modalRef.componentInstance.disabledScopes = {upload: true, inline: true, pdf: true, client: true, fhir: true, 'pdf-form': true};
  }

  /**
   * Adds default gdt scoring variables, if not already added.
   * Uses gdt key 8480 by default (result text)
   *
   */
  public addDefaultGdtScoringVariables() {
    if (!this.questionnaire.meta.options.gdt || !this.questionnaire.meta.options.gdt.addScoring) {
      return;
    }

    const scoringPathHashes = Object.keys(this.questionnaire.meta.options.variables['scoring'] || {}).map(varName => sha.sha256(varName));

    if (!scoringPathHashes.length) {
      return;
    }

    const gdtVariables = this.variables.getVariablesForScope('gdt');
    const flipped = this.variables.flipVariables(gdtVariables);

    for (const scoringPathHash of scoringPathHashes) {
      if (flipped[scoringPathHash]) {
        continue;
      }

      this.variables.addVariableMapping('gdt', '8480', scoringPathHash, {});
    }
  }

  /**
   * Returns true when questionnaire contains scoring variables
   * @returns {boolean}
   */
  public hasScoring(): boolean {
    const scoring = this.questionnaire.meta.options.variables['scoring'];
    return scoring && Object.keys(scoring).length > 0
  }

  /**
   * Returns scoring variables as array
   * @returns {string[]}
   */
  public getScoringVariables(): string[] {
    return Object.keys(this.questionnaire.meta.options.variables['scoring'] || {});
  }

  /**
   * Starts new questionnaire session. Creates snapshot, so we can detect
   * if questionnaire contains unsaved changes
   *
   * @param {Questionnaire} questionnaire
   */
  private startSession(questionnaire: Questionnaire) {
    this.questionnaire = questionnaire;
    this.questionnaireSnapshot = JSON.stringify(questionnaire);
  }

  /**
   * Returns true if questionnaire contains unsaved content
   *
   * @returns {boolean}
   */
  private isDirty() {
    const current = JSON.stringify(this.questionnaire);
    return current != this.questionnaireSnapshot;
  }

  /**
   * Memorizes current questionnaire, it has questions and contains unsaved content
   */
  private memorizeSession() {
    // memorize questionnaire, if it has questions and contains unsaved content
    const isDirty = this.questionnaire.questions.length && this.isDirty();
    isDirty && this.editor.memorizeQuestionnaire(this.questionnaire);
  }

  /**
   * Checks if a cached questionnaire is present and ask's user to resume it
   */
  private askResumeSession() {
    // early bail out if no memorized questionnaire present
    if (!this.editor.hasMemorizedQuestionnaire()) {
      return;
    }

    setTimeout(() => {
      (async () => {
        try {
          const resume = await confirm(this._(KEYS.EDITOR.ASK_RESUME_QUESTIONNAIRE));
          resume ? this.startSession(await this.editor.resumeMemorizedQuestionnaire()) : this.editor.deleteMemorizedQuestionnaire();

          if (resume) {
            this.layoutService.showPreloader();

            // previous session could contain an already deleted questionnaire. therefore we ask the api if the questionnaire exists
            let existsInDb = this.questionnaire.createdAt ? !!await this.api.hasQuestionnaire(this.questionnaire.id) : false;

            const path = window.location.pathname;
            const replaceValue = existsInDb ? `/editor/${this.questionnaire.id}` : '/editor';
            const newPath = path.replace(/\/editor($|\/.*$)/, replaceValue);
            window.history.pushState(null, null, newPath);

            this.editMode = existsInDb;
            !existsInDb && (this.questionnaire.revision = 1);
            setTimeout(() => this.layoutService.hidePreloader(), 1000);
          }
        } catch (error) {
          // TODO: log error!!!
          console.error(error);
        }
      })();
    }, 50);
  }

  /**
   * Applies license constrictions
   */
  private applyRestrictions() {
    const numQuest = this.configService.get('constraint.numQuestionnaires');

    if (!numQuest) {
      return;
    }

    this.api.getNumQuestionnaires().then(count => {
      if (count >= numQuest) {
        alert(this._(KEYS.EDITOR.QUESTIONNAIRE_LIMIT_REACHED));
        this.location.back();
      }
    })
  }
}
