import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NavigationEnd, Router} from '@angular/router';
import {LayoutService} from '@ngmedax/layout';
import {ConfigService} from '@ngmedax/config';
import {KeyPairGenerator, Converter, Signer, LicenseDecoder} from '@ngmedax/common-license';

import {routing} from './license-manager.routing';
import {NAVIGATION} from './license-manager.navigation';
import {TruncatePipe} from './pipes/truncate.pipe';
import {StriptagsPipe} from './pipes/striptags.pipe';
import {HtmlDecodePipe} from './pipes/htmldecode.pipe';
import {DatexPipe} from './pipes/datex.pipe';
import {LicenseManagerService} from './services/license-manager.service';
import {LicenseManagerGridComponent} from './components/license-manager-grid.component';
import {LicenseManagerCrudComponent} from './components/license-manager-crud.component';
import {Translatable} from '@ngmedax/translation';
import {TRANSLATION_DEFAULT_SCOPE} from '../../constants';
import {KEYS} from '../../translation-keys';
import {filter} from 'rxjs/operators';
import {KeyPairStorageService} from './services/key-pair-storage.service';
import {LicenseGeneratorProvider} from './providers/license-generator';
import {LicenseCipher} from './services/license-cipher.service';


// hack to inject decorator declarations. must occur before class declaration!
export interface LicenseManagerModule extends Translatable {}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    routing
  ],
  declarations: [
    LicenseManagerGridComponent,
    LicenseManagerCrudComponent,
    HtmlDecodePipe,
    StriptagsPipe,
    TruncatePipe,
    DatexPipe
  ],
  exports: [],
  entryComponents: [
    LicenseManagerCrudComponent
  ],
  providers: [
    LicenseManagerService,
    KeyPairStorageService,
    LicenseGeneratorProvider,
    {provide: LicenseDecoder, useValue: new LicenseDecoder(new Converter(), new Signer(new Converter()))},
    {provide: KeyPairGenerator, useValue: new KeyPairGenerator()},
    {provide: Converter, useValue: new Converter()},
    LicenseCipher
  ]
})
@Translatable({scope: TRANSLATION_DEFAULT_SCOPE, keys: KEYS})
export class LicenseManagerModule {
  private firstRun = true;
  public constructor(
    private layoutService: LayoutService,
    private configService: ConfigService,
    private router: Router
  ) {
    // subscribe to navigation end event
    const subscription = router.events
      .pipe(<any>filter((event:Event) => event instanceof NavigationEnd))
      .subscribe(() => {
        if (this.configService.get('mode.license.manager')) {
          layoutService.addMenuEntry(NAVIGATION, {top: true});
          layoutService.getMenu()[0].children.length > 1 && layoutService.getMenu()[0].children.pop();

          layoutService.setMenu(layoutService.getMenu().filter(m =>
            m.path.match(/module\/license-manager/)
            || m.path.match(/module\/support\/info/)
            || m.path.match(/module\/ums/)
          ));

          layoutService.setHomeComponent(LicenseManagerGridComponent);

          if (this.firstRun) {
            this.firstRun = false;
            this.router.navigate(['module', 'home']).then().catch();
          }
        }
      });
  }
}
