import {Component, EventEmitter, Input, OnInit, Optional, ViewEncapsulation} from '@angular/core';
import {DATE_FORMAT_YMD_HMS, Translatable, TranslationService} from '@ngmedax/translation';
import {RegistryService} from '@ngmedax/registry';
import {LicenseGenerator, LicenseDecoder} from '@ngmedax/common-license';
const isValidDomain = require('is-valid-domain');
import * as moment from 'moment';

import {TRANSLATION_HISTORY_SCOPE} from '../../../constants';
import {KEYS} from '../../../translation-keys';
import {License} from '../../../types';
import {DateFormatService} from '../../../../translation';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {LicenseManagerService} from '../services/license-manager.service';
import {KeyPairStorageService} from '../services/key-pair-storage.service';
import {LicenseCipher} from '../services/license-cipher.service';


// hack to inject decorator declarations. must occur before class declaration!
export interface LicenseManagerCrudComponent extends Translatable {}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-license-manager-crud',
  templateUrl: './license-manager-crud.component.html',
  styleUrls: ['./license-manager-crud.component.css'],
})
@Translatable({scope: TRANSLATION_HISTORY_SCOPE, keys: KEYS})
export class LicenseManagerCrudComponent implements OnInit {
  /**
   * Locale hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi lang support
   * @type {string}
   */
  public locale = 'de_DE';

  /**
   * License
   * @type {License}
   */
  public license: License = null;

  /**
   * Is unlocked?
   */
  public isUnlocked: boolean = null;

  /**
   * Decrypted license key
   */
  public decryptedLicenceKey: string = null;

  /**
   * Domains
   */
  public domains: string = null;

  /**
   * Valid until
   */
  public validUntil: string = null;

  /**
   * Default for new licenses
   */
  private defaultLicense: License = {
    name: '',
    nonce: '',
    licenseKey: '',
    feature: {
      extendedWysiwyg: true,
      appAllowMail: false,
      appLinkSend: false,
      appLinkCopy: false,
      appLinkOpen: true,
      anonPatient: true,
      appPatientUpload: false,
      pdfForms: false,
      signoSign: false
    },
    constraint: {}
  };

  /**
   */
  public ngOnInit() {
    this.onReset();
    this.keyPairStorageService.isUnlocked().then(isUnlocked => this.isUnlocked = isUnlocked);
  }

  /**
   * Injects dependencies
   */
  public constructor(
    public activeModal: NgbActiveModal,
    @Optional() private translationService: TranslationService,
    @Optional() private dateFormatService: DateFormatService,
    private licenseManagerService: LicenseManagerService,
    private licenseGeneratorService: LicenseGenerator,
    private licenseDecoderService: LicenseDecoder,
    private keyPairStorageService: KeyPairStorageService,
    private registryService: RegistryService,
    private cipher: LicenseCipher
  ) {
  }

  /**
   * Loads license
   * @param licenseId
   */
  public load(licenseId: string) {
    (async () => {
      try {
        const license = await this.licenseManagerService.loadLicense(licenseId);

        if (this.isUnlocked) {
          const keyPair = await this.keyPairStorageService.getKeyPair();
          this.decryptedLicenceKey = this.cipher.decrypt(license.licenseKey, keyPair);
        }

        !license.constraint && (license.constraint = {});
        license.constraint && license.constraint.domains && (this.domains = license.constraint.domains.join(', '))
        license.validUntil && (this.validUntil = moment(license.validUntil).format('YYYY-MM-DD'))
        this.license = license;
      } catch (error) {
        alert('Beim Laden der Lizenz ist ein Fehler aufgetreten!');
        throw(error);
      }
    })();
  }

  /**
   * date format to use
   * @type {string}
   */
  public get dateFormat() {
    const format = DATE_FORMAT_YMD_HMS.replace(/YYYY/, 'YY');
    return this.getDateFormat(format);
  };

  /**
   * Returns true if we can save
   */
  public canSave() {
    return this.isUnlocked && this.validUntil && this.license.name;
  }

  /**
   * Saves current license
   */
  public async onSave() {
    try {
      if (this.decryptedLicenceKey) {
        const shouldOverwrite = await <any>confirm('Achtung: Diese Aktion überschreibt den vorherigen Lizenzschlüssel! Fortfahren?');
        if (!shouldOverwrite) {
          return;
        }
      }

      await this.onGenerate();
      await this.licenseManagerService.saveLicense(this.license);
      this.licenseManagerService.onLicenseSaved().emit();
    } catch (error) {
      alert('Beim Speichern der Lizenz ist ein Fehler aufgetreten!');
      throw(error);
    }

    alert('Die Lizenz wurde erfolgreich gespeichert.');
  }

  onReset() {
    this.license = this.defaultLicense;
    this.decryptedLicenceKey = '';
    delete this.domains;
    delete this.validUntil;
  }

  async onGenerate() {
    const values = this.getValues();
    const isInvalidDomains = values.constraint && values.constraint.domains && !this.isValidDomains(values.constraint.domains);

    if (isInvalidDomains) {
      const msg = 'Fehler: Lizenz konnte nicht generiert werden. Bitte gültige Domain(s) eingeben. Ohne Protokoll (http://,https://)! '
        + 'Wildcard Domains sind nicht erlaubt.';
      alert(msg);
      return;
    }

    const keyPair = await this.keyPairStorageService.getKeyPair();
    this.decryptedLicenceKey = this.licenseGeneratorService.generate(values, keyPair);
    const decoded = await this.licenseDecoderService.decode(this.decryptedLicenceKey, keyPair);
    this.license.licenseKey = this.cipher.encrypt(this.decryptedLicenceKey, keyPair);
    this.license.validUntil = moment(decoded.validUntil, 'YYYY-MM-DD').toJSON();
    !this.license.constraint && (this.license.constraint = {});
    values.constraint && values.constraint.domains && values.constraint.domains.length
      && (this.license.constraint.domains = values.constraint.domains);
    this.license.nonce = decoded.nonce;
  }

  /**
   * Returns license values
   */
  private getValues() {
    const validUntil = this.validUntil;
    const domains = this.domains ? this.domains.split(',').map(domain => domain.trim()) : null;
    const values = JSON.parse(JSON.stringify(this.license));

    // todo: use whitelist instead
    delete values.id;
    delete values.uid;
    delete values._id;
    delete values.__v;
    delete values.licenseKey;
    delete values.constraint.domains;
    delete values.validUntil;
    delete values.updatedAt;
    delete values.createdAt;
    delete values.partner;
    delete values.nonce;

    validUntil && (values.validUntil = validUntil);
    domains && (values.constraint.domains = domains);
    !Object.keys(values.constraint).length && delete values.constraint;
    return values;
  }

  /**
   * Returns true if format of given domains is valid
   */
  private isValidDomains(domains: string[]): boolean {
    for (const domain of domains) {
      if (!isValidDomain(domain.replace(/:[0-9]*$/, ''), {subdomain: true, wildcard: false})) {
        return false;
      }
    }

    return true;
  }
}
